export enum CmsPageSectionType {
  TrustPilot = "ContentfulTrustPilotSection",
  CardPanel = "ContentfulCardPanel",
  PageHero = "ContentfulPageHero",
  PageCarousel = "ContentfulPageCarousel",
  BlogList = "ContentfulBlogList",
  Category = "ContentfulCategory",
  LatestPosts = "ContentfulLatestPostsCarousel",
  ContentHubNavBar = "ContentfulContentHubNavBar",
  FourCardPanel = "ContentfulFourCardPanel",
  DismissibleBanner = "ContentfulDismissibleBanner",
  PaddedPageBanner = "ContentfulPaddedPageBanner",
  CenteredImagePanel = "ContentfulCenteredImagePanel",
  DisclaimerSection = "ContentfulDisclaimerSection",
  EmbeddedVideoSection = "ContentfulEmbeddedVideoSection",
  InfoAccordion = "ContentfulInfoAccordion",
  ComparisonTableSection = "ContentfulComparisonTableSection",
  QuoteCard = "ContentfulQuoteCard",
  CalculatorSection = "ContentfulCalculatorSection",
  TickerTapeSection = "ContentfulTickerTapeSection"
}
