import { graphql } from "gatsby";
import React, { FC } from "react";
import { CmsPage } from "components/ComposedPage";
import { ComposedPage } from "../components/ComposedPage";

export interface HomePageProps {
  data: {
    page: CmsPage;
  };
}

export const HomePageTemplate: FC<HomePageProps> = ({ data: { page } }) => {
  return (
    <ComposedPage
      title={page.title}
      metaTitle={page.metaTitle}
      metaDescription={page.metaDescription}
      sections={page.sections}
      slug={page.slug}
      aboveFoldExperimentation={page.aboveFoldExperimentation}
      jsonSchema={page.jsonSchema}
    />
  );
};

export default HomePageTemplate;

export const pageQuery = graphql`
  query SiteRoot {
    page: contentfulRootPage(contentful_id: { eq: "3F6ihxU6J8gwKKpR78jt7N" }) {
      metaDescription
      metaTitle
      jsonSchema {
        jsonSchema
      }
      aboveFoldExperimentation
      title
      transparentHeader
      id
      sections {
        ...ContentfulCardPanelFragment
        ...ContentfulPageHeroFragment
        ...ContentfulLatestPostsCarouselFragment
        ...ContentfulPageCarouselFragment
        ...ContentfulTrustPilotSectionFragment
        ...ContentfulFourCardPanelFragment
        ...ContentfulDismissibleBannerFragment
        ...ContentfulPaddedPageBannerFragment
        ...ContentfulCenteredImagePanelFragment
        ...ContentfulDisclaimerSectionFragment
        ...ContentfulEmbeddedVideoSectionFragment
        ...ContentfulComparisonTableSectionFragment
        ...ContentfulQuoteCardFragment
        ...ContentfulBlogPostRichTextSectionFragment
        ...ContentfulInfoAccordionFragment
        ...ContentfulCalculatorSectionFragment
        ...ContentfulTickerTapeSectionFragment
      }
    }
  }
`;
