import React, { FC } from "react";
import { Link } from "components/Link";
import { BlogPostMetadata } from "../BlogPost/PostMetadata";
import { CmsBlogPost } from "../types";
import styles from "./BlogPostCard.module.scss";

export interface CardTextProps {
  post: CmsBlogPost;
  showMetadata?: boolean;
  url: string;
  imageCritical?: boolean;
  showDate?: boolean;
}

export const BlogPostCardText: FC<CardTextProps> = ({
  post,
  url,
  showMetadata = true,
  imageCritical,
  showDate = false
}) => (
  <div className={styles.text}>
    {imageCritical ? (
      <h5 itemProp="headline" role="heading" className={styles.imageCritical}>
        {/* Link below is not tabbable; the whole BlogPostCard acts as tabbable link */}
        <Link
          className={styles.blogLink}
          url={url}
          tabIndex={-1}
          itemProp="url"
        >
          {post.title}
        </Link>
      </h5>
    ) : (
      <p itemProp="headline" role="heading">
        {/* Link below is not tabbable; the whole BlogPostCard acts as tabbable link */}
        <Link
          className={styles.blogLink}
          url={url}
          tabIndex={-1}
          itemProp="url"
        >
          {post.title}
        </Link>
      </p>
    )}

    {showMetadata && (
      <BlogPostMetadata
        showDate={showDate}
        categories={post.categories}
        className={styles.metadata}
        readingTime={post.readingTime}
        createdAt={post.publishedDate || post.createdAt}
      />
    )}
  </div>
);
BlogPostCardText.displayName = "BlogPostCardText";
