import { CmsPage, ComposedPage } from "components/ComposedPage";
import { graphql } from "gatsby";
import React, { FC } from "react";

export interface FeaturesPageProps {
  data: {
    page: CmsPage;
  };
}

export const FeaturesPageTemplate: FC<FeaturesPageProps> = ({
  data: { page }
}) => (
  <ComposedPage
    title={page.metaTitle || page.title}
    metaTitle={page.metaTitle}
    metaDescription={page.metaDescription}
    noIndex={page.noIndex}
    sections={page.sections}
    slug={page.slug}
    aboveFoldExperimentation={page.aboveFoldExperimentation}
    jsonSchema={page.jsonSchema}
  />
);

export default FeaturesPageTemplate;

export const pageQuery = graphql`
  query FeaturesPageQuery($id: String!) {
    page: contentfulFeaturesPage(id: { eq: $id }) {
      title
      slug
      metaTitle
      metaDescription
      jsonSchema {
        jsonSchema
      }
      noIndex
      aboveFoldExperimentation
      sections {
        __typename
        ... on Node {
          ...ContentfulCardPanelFragment
          ...ContentfulPageHeroFragment
          ...ContentfulLatestPostsCarouselFragment
          ...ContentfulPageCarouselFragment
          ...ContentfulTrustPilotSectionFragment
          ...ContentfulFourCardPanelFragment
          ...ContentfulDismissibleBannerFragment
          ...ContentfulPaddedPageBannerFragment
          ...ContentfulCenteredImagePanelFragment
          ...ContentfulDisclaimerSectionFragment
          ...ContentfulEmbeddedVideoSectionFragment
          ...ContentfulInfoAccordionFragment
          ...ContentfulComparisonTableSectionFragment
          ...ContentfulQuoteCardFragment
          ...ContentfulBlogPostRichTextSectionFragment
          ...ContentfulCalculatorSectionFragment
          ...ContentfulTickerTapeSectionFragment
          ...ContentfulCategoryFragment
        }
      }
    }
  }
`;
