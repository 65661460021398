import React, { FC, useState } from "react";
import styles from "./styles.module.scss";
import { Button, ButtonVariants } from "../../Button";
import cx from "classnames";
import { CalculatorInput } from "../CalculatorInput";

interface SimpleInterestState {
  valid: boolean;
  monthly: string;
  yearly: string;
  savingGoal: string;
  rate: string;
}

export const SimpleInterestCalculator: FC = () => {
  const MAX_GOAL = 100000;
  const MAX_RATE = 100;
  const [calculatorState, setCalculatorState] = useState<SimpleInterestState>({
    valid: true,
    monthly: "0",
    yearly: "0",
    savingGoal: "200",
    rate: "3.65"
  });

  const calculate = () => {
    setCalculatorState(prev => {
      const parsedRate = parseFloat(prev.rate).toFixed(2);
      const parsedSavingGoal = String(parseInt(prev.savingGoal, 10));
      const monthly = (Number(parsedRate) / 100) * Number(parsedSavingGoal);

      return {
        ...prev,
        rate: parsedRate,
        savingGoal: parsedSavingGoal,
        monthly: parseFloat(String(monthly)).toFixed(2),
        yearly: parseFloat(String(monthly * 12)).toFixed(2)
      };
    });
  };

  const validInputs = (goal: string, rate: string) => {
    return (
      Number(goal) > 0 &&
      Number(goal) <= MAX_GOAL &&
      Number(rate) > 0 &&
      Number(rate) <= MAX_RATE
    );
  };

  const handleSavingInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const val = e.target.value.replace(/-/, "");

      setCalculatorState(prev => {
        return {
          ...prev,
          savingGoal: val,
          valid: validInputs(val, prev.rate)
        };
      });
    }
  };

  const handleInterestInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target) {
      const val = e.target.value.replace(/-/, "");

      setCalculatorState(prev => {
        return {
          ...prev,
          rate: val,
          valid: validInputs(prev.savingGoal, val)
        };
      });
    }
  };

  return (
    <div className={styles.calculator}>
      <div className={styles.inputs}>
        <CalculatorInput
          changeHandler={handleSavingInputChange}
          name="saving_goal"
          label="Saving goal"
          type="number"
          invalidMessage="Invalid number"
          unit="currency"
          max={MAX_GOAL}
          helpText="How much do you want to save a month?"
          value={calculatorState.savingGoal}
          placeholder="0"
        />

        <CalculatorInput
          changeHandler={handleInterestInputChange}
          name="interest_rate"
          type="number"
          label="Interest rate"
          invalidMessage="Invalid number"
          max={MAX_RATE}
          unit="percentage"
          helpText="The interest rate on the monthly amount."
          value={calculatorState.rate}
          placeholder="0"
        />
      </div>

      <div>
        <Button
          className={styles.button}
          onClick={calculate}
          variant={ButtonVariants.PRIMARY}
          disabled={!calculatorState.valid}
        >
          Calculate
        </Button>
      </div>

      <div className={styles.results}>
        <p className={styles.title}>Interest earned</p>
        <div className={styles.outputs}>
          <div>
            Monthly
            <span
              data-testid="monthly"
              className={cx(styles.result, {
                [styles.nonZero]: Number(calculatorState.monthly) !== 0
              })}
            >
              £{calculatorState.monthly}
            </span>
          </div>
          <div>
            Yearly
            <span
              data-testid="yearly"
              className={cx(styles.result, {
                [styles.nonZero]: Number(calculatorState.yearly) !== 0
              })}
            >
              £{calculatorState.yearly}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

SimpleInterestCalculator.displayName = "SimpleInterestCalculator";
