import React, { FC } from "react";
import * as gatsby from "gatsby";
import styles from "./styles.module.scss";

type LogoProps = {
  src: string;
  title: string;
};

export const Logo: FC<LogoProps> = ({ src, title }) => {
  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      gatsby.navigate("/");
    }
  };

  return (
    <div className={styles.logo}>
      <img
        src={src}
        alt={title}
        tabIndex={0}
        onKeyDown={handleKeydown}
        onClick={() => gatsby.navigate("/")}
      />
    </div>
  );
};
