import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import {
  AnimationTrigger,
  CardCopyPosition,
  CardProps
} from "components/CardPanel";
import cx from "classnames";
import { FullScreenTakeover } from "./FullScreenTakeover";
import { IconographyButton } from "components/IconographyButton";
import plus from "assets/icons/plus.svg";
import { CardTakeover } from "./CardTakeover";
import { CardCopy } from "./CardCopy";
import { CardBackgroundImage } from "components/CardBackgroundImage";
import { useIsBlogPage } from "hooks/useIsBlogPage";
import { Colour } from "../constants";
import { CardImage } from "./CardImage";
import { LottieAnimation } from "../LottieAnimation";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { InlineVideo } from "../InlineVideo";
import { useInitialScroll } from "../../hooks/useInitialScroll";
import { Video } from "../Video";

export const Card: React.FC<CardProps> = ({
  title,
  number,
  index,
  fullScreenTakeover,
  cardTakeover,
  urlDisplay,
  url,
  subtitle,
  iconImage,
  bodyCopy,
  backgroundColour = Colour.warmStone,
  rowLength = 1,
  image,
  lottieFile,
  inlineVideo,
  video,
  videoPoster,
  mobileVideo,
  mobileVideoPoster,
  loopAnimation = false,
  animationTrigger,
  imageFit,
  hideImageOnMobile,
  backgroundImage,
  padCopy = true,
  increasedPadding = false,
  copyPosition = CardCopyPosition.TOP,
  richText,
  richTextListMarkerStyle,
  showOpenAccountButton,
  openAccountButtonText,
  primaryCtaText,
  primaryCtaUrl,
  inCarousel,
  animateCardPosition,
  reduceImageSize = false,
  reverseOrder = false
}) => {
  const [showFullScreenTakeover, setShowFullScreenTakeOver] = useState(false);
  const [showCardTakeover, setShowCardTakeOver] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const [clickableCard, setClickableCard] = useState(false);
  const [cardClicked, setCardClicked] = useState(false);

  const MAX_CTAS_FOR_CLICKABLE_CARD = 1;
  const shouldWatchScrollIntoView =
    (lottieFile || inlineVideo) &&
    animationTrigger === AnimationTrigger.onScrollIntoView;
  const shouldWatchOnScroll =
    (lottieFile || inlineVideo) &&
    animationTrigger === AnimationTrigger.onScroll;
  const triggerInternalAnimationOnIntersect = useIntersectionObserver(
    cardRef,
    shouldWatchScrollIntoView
  );
  const triggerInternalAnimationOnScroll =
    useInitialScroll(shouldWatchOnScroll);
  const triggerPositionAnimation = useIntersectionObserver(
    cardRef,
    animateCardPosition,
    0.8
  );

  useEffect(() => {
    if (cardRef.current) {
      setClickableCard(
        cardRef.current.querySelectorAll("a, button").length ===
          MAX_CTAS_FOR_CLICKABLE_CARD
      );
    }
  }, []);

  const handleOpenFullScreenTakeover = () => {
    setShowFullScreenTakeOver(true);
  };

  const handleCloseFullScreenTakeover = () => {
    setShowFullScreenTakeOver(false);
  };

  const toggleCardTakeover = () => {
    setShowCardTakeOver(!showCardTakeover);
  };

  const isSingleRow = () => rowLength === 1;
  const reducedHeaders = () => rowLength === 3;

  const isBlog = useIsBlogPage();
  const hasContent = () =>
    title ||
    number ||
    subtitle ||
    bodyCopy ||
    richText ||
    (primaryCtaText && primaryCtaUrl) ||
    showOpenAccountButton ||
    (url && urlDisplay);

  const handleCardClick = () => {
    if (clickableCard && cardRef.current && !showCardTakeover) {
      const target = cardRef.current.querySelectorAll(
        "a, button"
      )[0] as HTMLElement;

      target?.click();
      setCardClicked(true);
    }
  };

  useEffect(() => {
    const setClicked = () => setCardClicked(false);

    window.addEventListener("resize", setClicked);

    return () => window.removeEventListener("resize", setClicked);
  }, []);

  return (
    <>
      <div
        ref={cardRef}
        onClick={handleCardClick}
        className={cx(styles.card, styles[backgroundColour], {
          [styles.triggerPositionAnimation]: triggerPositionAnimation,
          [styles.animateCardPosition]: animateCardPosition,
          [styles.delayTransition1]: index === 1,
          [styles.delayTransition2]: index === 2,
          [styles.blog]: isBlog,
          [styles.hasBackgroundImage]: Boolean(backgroundImage),
          [styles.hasContent]: hasContent(),
          [styles.inCarousel]: inCarousel,
          [styles.clickable]: clickableCard && !showCardTakeover,
          [styles.hasVideo]: Boolean(video || mobileVideo)
        })}
      >
        <div
          className={cx(styles.inner, {
            [styles.singleCardRow]: isSingleRow(),
            [styles.reverseOrder]: reverseOrder,
            [styles.multiCardRow]: !isSingleRow()
          })}
        >
          {hasContent() && (
            <CardCopy
              className={cx(styles.cardCopy, {
                [styles.reduceImageSize]: reduceImageSize
              })}
              hasBackground={Boolean(backgroundImage)}
              hideImageOnMobile={hideImageOnMobile}
              padCopy={padCopy}
              increasedPadding={increasedPadding}
              copyPosition={copyPosition}
              title={title}
              number={number}
              subtitle={subtitle}
              iconImage={iconImage}
              bodyCopy={bodyCopy}
              url={url}
              urlDisplay={urlDisplay}
              richText={richText}
              richTextListMarkerStyle={richTextListMarkerStyle}
              showOpenAccountButton={showOpenAccountButton}
              openAccountButtonText={openAccountButtonText}
              primaryCtaUrl={primaryCtaUrl}
              primaryCtaText={primaryCtaText}
              reducedHeaders={reducedHeaders()}
              backgroundColour={backgroundColour}
              hasImage={Boolean(image || lottieFile)}
              singleCardRow={isSingleRow()}
              reverseOrder={reverseOrder}
            />
          )}

          {image && (
            <CardImage
              className={cx(styles.illustration, styles.staticImage, {
                [styles.triggerPositionAnimation]: triggerPositionAnimation,
                [styles.animateCardPosition]: animateCardPosition,
                [styles.delayTransition1]: index === 1,
                [styles.delayTransition2]: index === 2
              })}
              imageFit={imageFit}
              image={image}
              hideImageOnMobile={hideImageOnMobile}
            />
          )}

          {lottieFile && (
            <LottieAnimation
              loop={loopAnimation}
              className={styles.illustration}
              play={
                triggerInternalAnimationOnIntersect ||
                triggerInternalAnimationOnScroll
              }
              path={lottieFile.file.url}
            />
          )}

          {inlineVideo && (
            <InlineVideo
              src={inlineVideo.file.url}
              play={
                triggerInternalAnimationOnIntersect ||
                triggerInternalAnimationOnScroll
              }
              loop={loopAnimation}
            />
          )}

          {video && (
            <Video
              video={video}
              videoPoster={videoPoster}
              mobileVideo={mobileVideo}
              mobileVideoPoster={mobileVideoPoster}
              play={cardClicked}
            />
          )}
        </div>

        {fullScreenTakeover && (
          <div className={styles.cta}>
            <IconographyButton
              icon={plus}
              value="Open"
              disabled={false}
              handler={handleOpenFullScreenTakeover}
            />
          </div>
        )}

        {cardTakeover && (
          <div
            className={cx(styles.cta, styles.cardTakeover, {
              [styles.shown]: showCardTakeover
            })}
          >
            <IconographyButton
              className={styles.cardButton}
              icon={plus}
              value={showCardTakeover ? "Close" : "Open"}
              disabled={false}
              handler={toggleCardTakeover}
            />
          </div>
        )}

        {cardTakeover && (
          <CardTakeover
            toggleCardTakeover={toggleCardTakeover}
            shown={showCardTakeover}
            cardTakeover={cardTakeover}
            hideImageOnMobile={hideImageOnMobile}
            singleCardRow={isSingleRow()}
          />
        )}
        {backgroundImage && <CardBackgroundImage image={backgroundImage} />}
      </div>

      {fullScreenTakeover && (
        <FullScreenTakeover
          fullScreenTakeover={fullScreenTakeover}
          closeHandler={handleCloseFullScreenTakeover}
          shown={showFullScreenTakeover}
        />
      )}
    </>
  );
};
