import * as Sentry from "@sentry/gatsby";
import { BranchData, BranchIO } from "branch-sdk";
import React, {
  FC,
  useEffect,
  createContext,
  useContext,
  createElement,
  useMemo,
  FocusEvent,
  MouseEventHandler,
  useState,
  useCallback
} from "react";
import { useEventListener } from "@mettle/hooks";
import { useConfig } from "./useConfig";
import useFilteredException from "./useFilteredException";
import { retry } from "./retry";
import debounce from "lodash/debounce";

type HTMLTextInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

/**
 * This module adds an integration layer for GTM's `dataLayer` and Branch.io
 * It exports a set of React hooks for pushing different types of events.
 */
declare global {
  interface Window {
    dataLayer: DataLayer;
    branch: BranchIO;
    OneTrust?: {
      ToggleInfoDisplay: () => void;
    };
    OnetrustActiveGroups?: string;
  }
}

type DataLayer = AnalyticsEvent[];

interface PageLoadEvent extends BranchData {
  event: "page_loaded" | "page_unloaded";
  path: string;
}

interface FieldFocusEvent extends BranchData {
  event: "field_focussed" | "field_blurred";
  form: string;
  field: string;
}

interface FormSubmittedEvent extends BranchData {
  event: "form_submitted";
  form: string;
}

interface ModalEvent extends BranchData {
  event: "modal_opened" | "modal_closed";
  modal: string;
}

interface ScrolledIntoViewEvent extends BranchData {
  event: "scrolled_into_view";
  element: string;
  page: string;
}

interface SearchedEvent extends BranchData {
  event: "searched";
  term: string;
  results: string;
  page: string;
}

interface ButtonPressEvent extends BranchData {
  event: "button_pressed";
  button_text: string;
  button_id: string;
}

interface ExternalNavigationEvent extends BranchData {
  event: "external_navigation";
  url: string;
}

export enum VideoEventType {
  PLAY = "play",
  PAUSE = "pause",
  SEEKED = "seeked",
  ENDED = "ended",
  VIDEO_DEPTH = "video-depth"
}

interface VideoMetadata {
  fileName: string;
  videoType: string;
  page: string;
}

interface VideoEvent {
  event: VideoEventType;
  videoTime: string;
  value?: string;
}

interface VideoAnalyticsEvent extends BranchData, VideoMetadata, VideoEvent {}

type FormEvent = FieldFocusEvent | FormSubmittedEvent;
export type AnalyticsEvent =
  | PageLoadEvent
  | FormEvent
  | ModalEvent
  | ButtonPressEvent
  | ExternalNavigationEvent
  | VideoAnalyticsEvent
  | ScrolledIntoViewEvent
  | SearchedEvent;

export interface Analytics {
  dataLayer: DataLayer;
  branch: BranchIO | null;
  tracking_disabled: boolean;
  branchInitialised: boolean;
}

const initialAnalyticsContext: Analytics = {
  dataLayer: [] as DataLayer,
  branch: null,
  tracking_disabled: true,
  branchInitialised: false
};

export const AnalyticsContext = createContext<Analytics>(
  initialAnalyticsContext
);

const PERFORMANCE_COOKIES_GROUP = "C0002";

export const DefaultAnalyticsProvider: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const config = useConfig();

  const [ctx, setCtx] = useState<Analytics>(initialAnalyticsContext);

  useEffect(() => {
    if (config) {
      Sentry.configureScope(scope => {
        scope.setTag("environment", config.ENV);
      });

      /**
       * GTM dataLayer tracking uses a globally defined Array-like object called `dataLayer`.
       * In the event that it's not defined, we will log to the console and define our own
       * array to ensure everything continues to work as expected.
       */
      if (!window.dataLayer) {
        window.dataLayer = [] as DataLayer;
      }

      let tracking_disabled = true;
      const { BRANCH_IO_ENABLED, BRANCH_IO_KEY } = config;
      if (BRANCH_IO_ENABLED && BRANCH_IO_KEY) {
        tracking_disabled = window.OnetrustActiveGroups
          ? !window.OnetrustActiveGroups.includes(PERFORMANCE_COOKIES_GROUP)
          : true;

        // Importing BranchIO when we are sure this is client side environment, as library requires window object
        const BranchInstance: BranchIO = require("branch-sdk");
        const createBranchInitialisationPromise = () =>
          new Promise<void>((resolve, reject) => {
            BranchInstance.init(BRANCH_IO_KEY, { tracking_disabled }, err => {
              if (err) {
                reject(err);
              } else {
                resolve();
              }
            });
          });

        retry({
          fn: createBranchInitialisationPromise,
          maxAttempts: 3,
          delayInSeconds: 1
        })
          .then(() => {
            console.log("Branch sdk initialised successfully.");
            setCtx({
              dataLayer: window.dataLayer,
              branch: window.branch,
              tracking_disabled,
              branchInitialised: true
            });
          })
          .catch(e => {
            console.log("Failed to initialise Branch sdk:", e);
            setCtx({
              dataLayer: window.dataLayer,
              branch: window.branch,
              tracking_disabled,
              branchInitialised: false
            });
            useFilteredException(e);
          });
      }

      setCtx({
        dataLayer: window.dataLayer,
        branch: window.branch,
        tracking_disabled,
        branchInitialised: false
      });
    }
  }, [config]);

  const consentUpdatedListener = useCallback(() => {
    const tracking_disabled = window.OnetrustActiveGroups
      ? !window.OnetrustActiveGroups.includes(PERFORMANCE_COOKIES_GROUP)
      : true;

    if (ctx.branch) {
      ctx.branch.disableTracking(tracking_disabled);

      setCtx({
        ...ctx,
        tracking_disabled
      });
    }

    window["optimizely"].push({
      type: tracking_disabled ? "disable" : "activate"
    });

    if (document) {
      const el = document.getElementById("main");

      el?.setAttribute("style", "");
    }
  }, [ctx]);

  useEventListener(
    config && window,
    "OTConsentApplied" as any,
    consentUpdatedListener
  );

  return createElement(AnalyticsContext.Provider, { value: ctx }, children);
};
DefaultAnalyticsProvider.displayName = "DefaultAnalyticsProvider";

const logEventToBranchIO = <T extends AnalyticsEvent>(
  branch: BranchIO | null,
  branchInitialised: boolean,
  event: T
) => {
  if (branch && branchInitialised) {
    branch.logEvent(event.event, event, err => {
      if (err) {
        console.log(`Error logging event to Branch.io: ${err}`);
        Sentry.captureException(err);
      }
    });
  }
};

/**
 * A hook which allows firing analytics event.
 */
export function useAnalyticsEvent<T extends AnalyticsEvent>() {
  const { dataLayer, branch, tracking_disabled, branchInitialised } =
    useContext(AnalyticsContext);

  return useMemo(
    () => (event: T) => {
      if (!tracking_disabled) {
        // Branch currently has a bug, where it updates the event object before making the call to logEvent
        // https://github.com/BranchMetrics/web-branch-deep-linking-attribution/blob/5b7d15f3665d172b661fbd30719df5b415821baa/src/1_utils.js#L1128
        const pristineEvent = { ...event };
        logEventToBranchIO(branch, branchInitialised, event);
        dataLayer.push(pristineEvent);
      }
    },
    [dataLayer, tracking_disabled, branch, branchInitialised]
  );
}

/**
 * A hook which automatically fires pageload (and unload) events on
 * mount/unmount of any component it is used inside.
 */
export function usePageView(
  path: string = window.location.pathname,
  unload: boolean = true
) {
  const push = useAnalyticsEvent<PageLoadEvent>();
  useEffect(() => {
    push({ path, event: "page_loaded" });
    return unload
      ? () => void push({ path, event: "page_unloaded" })
      : undefined;
  }, [path, unload]);
}

export type FormAnalyticsHook = [
  () => void,
  <T extends HTMLTextInput>(field: FocusEvent<T>) => void,
  <T extends HTMLTextInput>(field: FocusEvent<T>) => void
];

export function useFormAnalytics(form: string): FormAnalyticsHook {
  const push = useAnalyticsEvent<FormEvent>();
  return useMemo<FormAnalyticsHook>(
    () => [
      () => void push({ form, event: "form_submitted" }),
      event =>
        void push({ form, event: "field_focussed", field: event.target.name }),
      event =>
        void push({ form, event: "field_blurred", field: event.target.name })
    ],
    [push, form]
  );
}

export const useButtonAnalytics = <E extends HTMLElement = HTMLButtonElement>(
  button_text: string,
  button_id: string,
  onClick?: MouseEventHandler<E>
): MouseEventHandler<E> => {
  const push = useAnalyticsEvent<ButtonPressEvent>();
  return useMemo(
    () => event => {
      push({ event: "button_pressed", button_text, button_id });
      onClick?.(event);
    },
    [button_text, button_id, onClick]
  );
};

export function useModalAnalytics(modal: string) {
  const push = useAnalyticsEvent<ModalEvent>();

  useEffect(() => {
    push({ modal, event: "modal_opened" });
    return () => void push({ modal, event: "modal_closed" });
  }, [modal]);
}

export const useExternalNavigation = () => {
  const push = useAnalyticsEvent<ExternalNavigationEvent>();
  return (url?: string, external?: boolean) => {
    if (url && external) {
      push({ event: "external_navigation", url });
    }
  };
};

export const useVideoAnalytics = (metadata: VideoMetadata) => {
  const push = useAnalyticsEvent<VideoAnalyticsEvent>();
  return useCallback(
    (event: VideoEvent) => {
      push({ ...metadata, ...event });
    },
    [metadata]
  );
};

export function useScrolledIntoViewEvent(
  element: ScrolledIntoViewEvent["element"],
  page: ScrolledIntoViewEvent["page"]
) {
  const push = useAnalyticsEvent<ScrolledIntoViewEvent>();
  return useCallback(() => {
    push({
      element,
      event: "scrolled_into_view",
      page
    });
  }, [element, page, push]);
}

export function useSearchedEvent(
  page: SearchedEvent["page"],
  debounceDelay = 3000
) {
  const push = useAnalyticsEvent<SearchedEvent>();

  const pushSearchEvent = useCallback(
    (term: SearchedEvent["term"], results: number) => {
      push({
        term,
        event: "searched",
        results: results.toString(),
        page
      });
    },
    [page, push]
  );

  return useCallback(
    debounce(pushSearchEvent, debounceDelay, {
      leading: false,
      trailing: true
    }),
    [pushSearchEvent, debounceDelay]
  );
}
