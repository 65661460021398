import * as gatsby from "gatsby";
import { urlIsExternal } from "./useLinkIsExternal";

export const withDefaultPrevented =
  <E extends HTMLElement, V extends React.SyntheticEvent<E, any>>(
    handleEvent: React.EventHandler<V>
  ): React.EventHandler<V> =>
  event => {
    if (event?.isDefaultPrevented?.()) return;
    event?.preventDefault?.();
    handleEvent(event);
  };

export const handleNavigation = <
  E extends HTMLElement,
  V extends React.SyntheticEvent<E, any>
>(
  url: string,
  external: boolean = urlIsExternal(url)
): React.EventHandler<V> =>
  withDefaultPrevented(
    external ? () => window.open(url, "_self") : () => gatsby.navigate(url)
  );
