import React, { FC, useCallback } from "react";
import cx from "classnames";
import { navigate } from "gatsby";
import { useBlogPostUrl } from "../hooks";
import { BlogPostCardText, CardTextProps } from "./BlogPostCardText";
import styles from "./BlogPostCard.module.scss";
import { getImage, IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image";

export interface BlogPostCardProps extends Omit<CardTextProps, "url"> {
  __typename: string;
  id: string;
  layout?: "triple" | "double" | "single";
  inCarousel?: boolean;
  showHeroImage?: boolean;
  showSubtitle?: boolean;
  className?: string;
  imageCritical?: boolean;
}

export const BlogPostCard: FC<BlogPostCardProps> = ({
  imageCritical,
  post,
  layout = "single",
  showHeroImage = true,
  className,
  inCarousel,
  ...cardTextProps
}) => {
  const postUrl = useBlogPostUrl(post);
  const handleCardClick = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      if (event.isDefaultPrevented()) {
        return;
      }
      navigate(postUrl);
    },
    [postUrl]
  );
  const heroImage = getImage(
    post?.heroImage?.gatsbyImageData as IGatsbyImageData
  );

  return (
    <article
      data-testid="card"
      onClick={handleCardClick}
      className={cx(
        styles.card,
        styles[layout],
        {
          [styles.imageCritical]: imageCritical,
          [styles.inCarousel]: Boolean(inCarousel)
        },
        className
      )}
      tabIndex={0}
      onKeyDown={event => event.key === "Enter" && handleCardClick(event)}
      itemProp="blogPost"
      itemScope
      itemType="http://schema.org/BlogPosting"
    >
      <BlogPostCardText
        post={post}
        url={postUrl}
        {...cardTextProps}
        imageCritical={imageCritical}
      />
      {showHeroImage && (
        <div className={cx(styles.hero)} data-testid="card-image">
          <GatsbyImage
            className={styles.backgroundImage}
            alt={post.title}
            image={heroImage as IGatsbyImageData}
          />
        </div>
      )}
    </article>
  );
};
BlogPostCard.displayName = "BlogPostCard";
